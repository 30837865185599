import { Tilt } from "react-tilt";

import "./AboutImage.css"

const defaultOptions = {
  reverse: false, // reverse the tilt direction
  max: 35, // max tilt rotation (degrees)
  perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1, // 2 = 200%, 1.5 = 150%, etc..
  speed: 1000, // Speed of the enter/exit transition
  transition: true, // Set a transition on enter/exit.
  axis: null, // What axis should be disabled. Can be X or Y.
  reset: true, // If the tilt effect has to be reset on exit.
  easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
};

const AboutImage = () => {
  return (
    <Tilt options={defaultOptions} className='tilt-container' >
        <img
          src="https://res.cloudinary.com/dnwvsqlh1/image/upload/f_auto,q_auto/v1/my-portfolio/about-image-1"
          alt="me-1"
          className="right-image"
        />
        <img
          src="https://res.cloudinary.com/dnwvsqlh1/image/upload/f_auto,q_auto/v1/my-portfolio/about-image-2"
          alt="me-2"
          className="left-image"
        />
    </Tilt>
  );
};

export default AboutImage;